import React, { useEffect, useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import validation from "utils/validation";
import { uploadFile, createFeedback } from "utils/api";
import Alert from "components/alert";

export const query = graphql`
    query($language: String!) {
        ...seo
        lang(key: $language) {
            ...footer
            feedback {
                title
                description
                heading
                types {
                    text
                    value
                }
                form {
                    name {
                        label
                        placeholder
                        required
                    }
                    email {
                        label
                        placeholder
                        required
                    }
                    type {
                        label
                    }
                    title {
                        label
                        placeholder
                        required
                    }
                    description {
                        label
                        placeholder
                        required
                    }
                    screenshots {
                        label
                    }
                    upload {
                        text
                    }
                    send {
                        text
                    }
                    alert {
                        title
                        message
                        close
                    }
                    consent {
                        label
                        description
                    }
                }
                menu {
                    menuItems {
                        title
                        url
                    }
                }
                image {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }
    }
`;

const FeedbackPage = ({
    data: {
        seo,
        lang: { feedback, footer },
    },
    pageContext: { language, translations },
}) => {
    const [loading, setLoading] = useState(false);
    const [imgs, setImgs] = useState([]);
    const [areFieldsFilled, setAreFieldsFilled] = useState({
        name: false,
        email: false,
        title: false,
        description: false,
        consent: false,
    });

    const constraints = {
        name: {
            presence: feedback.form.name.required,
        },
        email: {
            presence: feedback.form.email.required,
            email: true,
        },
        title: {
            presence: feedback.form.title.required,
        },
        description: {
            presence: feedback.form.description.required,
        },
    };

    const disabled =
        loading ||
        !areFieldsFilled.consent ||
        (!areFieldsFilled.name && constraints.name.presence) ||
        (!areFieldsFilled.email && constraints.email.presence) ||
        (!areFieldsFilled.title && constraints.title.presence) ||
        (!areFieldsFilled.description && constraints.description.presence);

    useEffect(() => {
        validation.init("feedback_form", constraints, () => {
            const query = new URLSearchParams(window.location.search);
            createFeedback({
                name: document.getElementById("name").value,
                email: document.getElementById("email").value,
                title: document.getElementById("title").value,
                tenant: query.get("tenant"),
                version: query.get("version"),
                description: document.getElementById("description").value,
                type: document.getElementById("type").value,
                screenshots: [
                    ...(document.getElementById("screenshots_container")
                        .children || []),
                ].map(e => e.id),
            });
            document.getElementById("title").value = "";
            document.getElementById("description").value = "";
            document.getElementById("screenshots_container").innerHTML = "";
            document.getElementById("alert").classList.remove("hidden");
        });
    }, []);

    return (
        <Layout
            menu={feedback.menu}
            footer={footer}
            translations={translations}
        >
            <SEO
                title={feedback.title}
                description={feedback.description}
                language={language}
                seo={seo}
            />
            <Alert {...feedback.form.alert} />
            <section id="feedback" className="section mx-auto pt-5">
                <div className="feedback__container container mx-auto">
                    <div className="feedback__content">
                        <h2
                            className="section__title"
                            data-sal="fade"
                            data-sal-easing="ease-in-cubic"
                        >
                            {feedback.heading}
                        </h2>
                        <p
                            data-sal="slide-up"
                            data-sal-easing="ease-in-cubic"
                            data-sal-delay="100"
                        >
                            {feedback.description}
                        </p>
                        <form
                            id="feedback_form"
                            className="w-full"
                            noValidate
                            data-sal="slide-up"
                            data-sal-easing="ease-in-cubic"
                            data-sal-delay="100"
                        >
                            <div className="input-group mb-2 mt-5">
                                <label
                                    htmlFor="name"
                                    className={
                                        feedback.form.name.required
                                            ? "required"
                                            : ""
                                    }
                                >
                                    {feedback.form.name.label}
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    className="input"
                                    name="name"
                                    placeholder={feedback.form.name.placeholder}
                                    onChange={e =>
                                        setAreFieldsFilled({
                                            ...areFieldsFilled,
                                            name: e.target.value !== "",
                                        })
                                    }
                                />
                            </div>
                            <div className="input-group mb-2">
                                <label
                                    htmlFor="email"
                                    className={
                                        feedback.form.email.required
                                            ? "required"
                                            : ""
                                    }
                                >
                                    {feedback.form.email.label}
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    className="input"
                                    name="email"
                                    placeholder={
                                        feedback.form.email.placeholder
                                    }
                                    onChange={e =>
                                        setAreFieldsFilled({
                                            ...areFieldsFilled,
                                            email: e.target.value !== "",
                                        })
                                    }
                                />
                            </div>
                            <div className="input-group mb-2">
                                <label htmlFor="type" className="required">
                                    {feedback.form.type.label}
                                </label>
                                <select
                                    id="type"
                                    className="input"
                                    name="type"
                                    defaultValue={feedback.types[0].value}
                                >
                                    {feedback.types.map(({ text, value }) => (
                                        <option key={value} value={value}>
                                            {text}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-group mb-2">
                                <label
                                    htmlFor="title"
                                    className={
                                        feedback.form.title.required
                                            ? "required"
                                            : ""
                                    }
                                >
                                    {feedback.form.title.label}
                                </label>
                                <input
                                    type="text"
                                    id="title"
                                    className="input"
                                    name="title"
                                    placeholder={
                                        feedback.form.title.placeholder
                                    }
                                    onChange={e =>
                                        setAreFieldsFilled({
                                            ...areFieldsFilled,
                                            title: e.target.value !== "",
                                        })
                                    }
                                />
                            </div>
                            <div className="input-group">
                                <label
                                    htmlFor="description"
                                    className={
                                        feedback.form.description.required
                                            ? "required"
                                            : ""
                                    }
                                >
                                    {feedback.form.description.label}
                                </label>
                                <textarea
                                    id="description"
                                    className="h-48"
                                    name="description"
                                    placeholder={
                                        feedback.form.description.placeholder
                                    }
                                    onChange={e =>
                                        setAreFieldsFilled({
                                            ...areFieldsFilled,
                                            description: e.target.value !== "",
                                        })
                                    }
                                ></textarea>
                            </div>
                            <div className="input-group">
                                <label htmlFor="screenshots">
                                    {feedback.form.screenshots.label}
                                </label>
                                <p></p>
                                <div
                                    id="screenshots_container"
                                    className="images"
                                >
                                    {imgs.map(img => (
                                        <div
                                            key={img.key}
                                            id={img.key}
                                            style={{
                                                backgroundImage: `url(${img.src})`,
                                            }}
                                        >
                                            <span
                                                className="remove-image"
                                                onClick={() =>
                                                    setImgs(
                                                        imgs.filter(
                                                            image =>
                                                                image.key !==
                                                                img.key
                                                        )
                                                    )
                                                }
                                            >
                                                X
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <p></p>
                                <label
                                    htmlFor="screenshots"
                                    className="btn inline-flex items-center pl-5"
                                >
                                    <i className="material-icons">add</i>
                                    {feedback.form.upload.text}
                                </label>
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    className="hidden"
                                    id="screenshots"
                                    onChange={event =>
                                        [...(event.target.files || [])].forEach(
                                            f =>
                                                uploadFile(
                                                    f,
                                                    () => setLoading(true),
                                                    (key, src) =>
                                                        setImgs([
                                                            ...imgs,
                                                            { key, src },
                                                        ]),
                                                    () => setLoading(false)
                                                )
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <p
                                    className="mb-2 mt-2 text-sm raw"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            feedback.form.consent.description,
                                    }}
                                />
                                <input
                                    type="checkbox"
                                    onChange={e =>
                                        setAreFieldsFilled({
                                            ...areFieldsFilled,
                                            consent: e.target.checked,
                                        })
                                    }
                                />{" "}
                                <label
                                    className="raw"
                                    dangerouslySetInnerHTML={{
                                        __html: feedback.form.consent.label,
                                    }}
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn--primary mt-8 inline-flex items-center px-4"
                                disabled={disabled}
                            >
                                <svg
                                    className={
                                        "animate-spin -ml-1 mr-3 h-5 w-5 text-white" +
                                        (loading ? "" : " hidden")
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                {feedback.form.send.text}
                            </button>
                        </form>
                    </div>
                    <div className="feedback__image">
                        <div
                            data-sal="slide-up"
                            data-sal-delay="400"
                            data-sal-duration="500"
                        >
                            <div className="feedback__image-wrap p-5">
                                <Img
                                    fluid={feedback.image.childImageSharp.fluid}
                                    alt="feedback"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default FeedbackPage;
